export class NotificationModel {
    private readonly id: string = this.getUniqueId();
    message: string | AnnouncementModel[];
    isSuccess: boolean;
    isClosable: boolean;
    isWithIcon?: boolean = false;
    isAlarmMessage?: boolean = false;
    isEventNotify?: boolean = false;

    constructor(
        message: string | AnnouncementModel[],
        isSuccess: boolean,
        isClosable: boolean = true,
        isWithIcon?: boolean,
        isAlarmMessage?: boolean,
        isEventNotify?: boolean,
    ) {
        this.message = message;
        this.isSuccess = isSuccess;
        this.isClosable = isClosable;
        this.isWithIcon = isWithIcon;
        this.isAlarmMessage = isAlarmMessage;
        this.isEventNotify = isEventNotify;
    }

    private getUniqueId(): string {
        if (self.crypto && typeof self.crypto.randomUUID === 'function') {
            return self.crypto.randomUUID();
        }
        return this.generateFallbackUUID();
    }

    private generateFallbackUUID(): string {
        return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, c => {
            const r = Math.random() * 16 | 0;
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    public getId(): string {
        return this.id;
    }
}

export interface AnnouncementModel {
    id?: number,
    title?: string,
    message: string,
    startAt?: string,
    endAt?: string,
    type?: string,
}

export interface AnnouncementModelApi {
    data: AnnouncementModel[],
}
